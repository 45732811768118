@font-face {
  font-family: 'BentonSans';
  src: local('BentonSans'), url(../fonts/BentonSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'BentonSans-Bold';
  src: local('BentonSans-Bold'), url(../fonts/BentonSans-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'BentonSans-Medium';
  src: local('BentonSans-Medium'), url(../fonts/BentonSans-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'BentonSans-Thin';
  src: local('BentonSans-Thin'), url(../fonts/BentonSans-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'BentonSans-Light';
  src: local('BentonSans-Light'), url(../fonts/BentonSans-Light.otf) format('opentype');
}

html, body {
  font-family: 'BentonSans', 'Helvetica Neue', helvetica, arial, sans-serif;
}

html, body, #root {
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
}
