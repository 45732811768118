@import "util/normalize";

$primary: #79589F;
$danger: #fb8c00;
$family-primary: 'BentonSans';
$card-content-padding: 38px;
$table-head-cell-border-width: 0 0 1px;
$table-foot-cell-border-width: 1px 0 0;
$table-cell-padding: 0.5em 0.75em;
$navbar-item-hover-color: #79589F;
$navbar-item-color: #323839;
$navbar-dropdown-arrow: #79589F;
$section-padding-desktop: 6rem 3rem;

@import "bulma/bulma.sass";
@import "main";
